import { My_personal_details } from "../../constants";
const Edit = (state = {
    loading: true,
    submitted: false,
    data: {
        "first_name": "",
        "last_name": "",
        "username": "",
        "birth_date": "",
        "city": "",
        "email": "",
        "phone_number": "",
        "address": "",
        "zip_code": ""
    }
}, action) => {
    switch (action.type) {
        case My_personal_details.EDIT_MY_PERSONAL_DETAILS:
            let new_data = action.payload.user ? {
                username: action.payload.user.username,
                first_name: action.payload.user.first_name,
                last_name: action.payload.user.last_name,
                email: action.payload.user.email,
                is_active: action.payload.user.is_active,
                about: action.payload.about,
                address: action.payload.address,
                address_latitude: action.payload.address_latitude,
                address_longitude: action.payload.address_longitude,
                birth_date: action.payload.birth_date,
                brands: action.payload.brands,
                city: action.payload.city,
                country: action.payload.city_details ? action.payload.city_details.country : null,
                city_latitude: action.payload.city_latitude,
                city_longitude: action.payload.city_longitude,
                created_at: action.payload.created_at,
                gender: action.payload.gender,
                google_calendar_id: action.payload.google_calendar_id,
                id: action.payload.id,
                image: action.payload.image,
                is_client: action.payload.is_client,
                is_master: action.payload.is_master,
                is_removed: action.payload.is_removed,
                phone_number: action.payload.phone_number,
                push_token: action.payload.push_token,
                rating: action.payload.rating,
                stripe_client_id: action.payload.stripe_client_id,
                stripe_customer_id: action.payload.stripe_customer_id,
                zip_code: action.payload.zip_code,
                city_details: action.payload.city_details
            } : {}
            return {
                ...state,
                submitted: false,
                data: { ...new_data }
            }
        case My_personal_details.GET_INPUT_VALUES:
            let edit_data = {
                ...state.data,
                [action.payload.label]: action.payload.data.value
            }
            return {
                ...state,
                data: edit_data
            }
        case My_personal_details.SET_MY_INFO:
            if (action.payload) {
                return {
                    ...state,
                    data: {
                        ...state.data,
                        ...action.payload
                    }
                }
            } else {
                return {
                    loading: true,
                    submitted: false,
                    data: {
                        "first_name": "",
                        "last_name": "",
                        "username": "",
                        "birth_date": "",
                        "city": "",
                        "email": "",
                        "phone_number": "",
                        "address": "",
                        "zip_code": ""
                    }
                }
            }

        case My_personal_details.SUBMITTED:
            return {
                ...state,
                submitted: true
            }
        default:
            state;
    }
    return state
}
export default Edit;