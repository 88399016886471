import { My_personal_details } from "../../constants";
const Settings = (state = {
    submitted: false,
    data: {
        "language": 2,
        "push_notification": true,
        "geolocation": true
    }
}, action) => {
    switch (action.type) {
        case My_personal_details.SETTINGS_DATA:
            return {
                ...state,
                data: {
                    ...state.data,
                    ...action.payload?.results
                }
            };
        case My_personal_details.SETTINGS_SUBMITTED:
            return {
                ...state,
                submitted: true,
            };
        case My_personal_details.SETTINGS:
            return {
                ...state,
                loading: false,
                submitted: false,
                text: ""
            };
        case My_personal_details.SETTINGS_LOADING:
            return {
                ...state,
                loading: true,
            };
        case My_personal_details.SETTINGS_INPUT:
            return {
                ...state,
                data: {
                    ...state.data,
                    ...action.payload
                }
            };
        case My_personal_details.SET_FOOTER:
            return {
                ...state,
                footer: { ...action.payload }
            };
        default:
            return state;
    }
    return state;
};
export default Settings;