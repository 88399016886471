import { call, put, takeEvery } from 'redux-saga/effects';
import cookies from 'next-cookies';
import { i18n } from 'next-i18next';
import { LoginRegis } from "../constants";
import {
    login_act, login_loading_act, login_submitted_act,
    regis_act, regis_loading_act, regis_submitted_act, regis_code_act, regis_code_submitted_act,
    feedback_loading_act, feedback_act, feedback_submitted_act, regis_code_loading_act
} from "../actions/login-regis";
import { get_me_act } from "../actions/my-info";
import Category from '../../services/api/login-regis';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const success_notify = (text) => toast.success(text ? text : "success", {
    position: toast.POSITION.TOP_CENTER
});
const error_notify = (text) => toast.error(text ? text : "Something went wrong", {
    position: toast.POSITION.TOP_CENTER
});
function* loginWithPhoneNumber(data) {
    try {
        const { cb, cbShowCode, ...rest } = data.payload;
        const login_res = yield Category.login_with_phone_number(rest);
        if (login_res?.message === 'OK') {
            cbShowCode();
        } else {
            cb();
            error_notify(i18n.t(login_res?.data?.message));
        }

    } catch (e) {
        error_notify();
        yield put(login_act({ open_alert: true }));
    }
}
function* codeForLogin(data) {
    try {
        const { cb, cbGoHome, ...rest } = data.payload;
        const login_res = yield Category.enter_code(rest);

        if (login_res.token) {
            // cb();
            cbGoHome()
            document.cookie = `token=${login_res.token}; path=/`;
            document.cookie = `user_id=${login_res.user.id}; path=/`;
        } else {
            cb();
            error_notify(i18n.t(login_res?.data?.message));
        }
        yield put(get_me_act({ ...login_res.user }));
        yield put(login_act({ ...login_res }));

    } catch (e) {
        error_notify();
        yield put(login_act({ open_alert: true }));
    }
}
function* login(data) {
    try {
        const { cb, cb_loader, ...rest } = data.payload;
        const post_data = rest;
        yield put(login_submitted_act());
        if (post_data.username.valid && post_data.password.valid) {
            // yield put(login_loading_act());
            const login_res = yield Category.login({
                role_code: post_data.role_code.value,
                username: post_data.username.value,
                password: post_data.password.value
            });

            login_res.token ? (document.cookie = `token=${login_res.token}; path=/`) : null;
            login_res.token ? (document.cookie = `user_id=${login_res.user.id}; path=/`) : null;
            // login_res.token ? cb() : cb_loader();
            if (login_res.token) {
                cb();
            } else {

                cb_loader();
                error_notify(login_res?.data?.message);
            }
            yield put(get_me_act({ ...login_res.user }));
            yield put(login_act({ ...login_res, open_alert: true }));
        } else {
            cb_loader();
        }
    } catch (e) {
        error_notify();
        yield put(login_act({ open_alert: true }));
    }
}
function* regis(data) {
    try {
        const { cb, agree, translate, ...post_data } = data.payload;
        const new_data = {
            role_code: post_data.role_code?.value,
            username: post_data.username?.value,
            password: post_data.password?.value,
            phone_number: post_data.phone_number?.value,
            email: post_data.email?.value,
            confirm_password: post_data.confirm_password?.value
        };
        document.cookie = `regis_data=${JSON.stringify(post_data)}; path=/`;
        yield put(regis_submitted_act());
        if (agree && post_data.phone_number.valid && post_data.username.valid && post_data.password.valid && post_data.email.valid && post_data.confirm_password.valid) {
            yield put(regis_loading_act());
            const regis_res = yield Category.registration(new_data);
            regis_res.token ? document.cookie = `user_id=${JSON.stringify(regis_res.user.id)}; path=/` : null;
            regis_res.token ? (document.cookie = `token=${regis_res.token}; path=/`) : null;
            if (regis_res.message === "OK") {
                if (window.location.href.includes("regis-code")) {
                    success_notify(translate.process_successful);
                } else {
                    cb();
                    error_notify()
                }
            } else {
                if (window.location.href.includes("regis-code")) {
                    error_notify();
                }
            }
            // regis_res.message === "OK" && !window.location.href.includes("regis-code") ? cb() : null
            yield put(regis_act({ message: regis_res.data ? regis_res.data[0].message : "", ...regis_res, open_alert: true, data: new_data }));
        }
    } catch (e) {
        console.log(e);
    }
}
function* regis_code(data) {
    try {
        const { cb, ...post_data } = data.payload;
        yield put(regis_code_submitted_act());
        if (post_data.confirm_code.valid) {
            yield put(regis_code_loading_act());
            const regis_res = yield Category.registration_code({
                role_code: post_data.role_code.value,
                username: post_data.username.value,
                password: post_data.password.value,
                phone_number: post_data.phone_number.value,
                email: post_data.email.value,
                confirm_password: post_data.confirm_password.value,
                confirm_code: post_data.confirm_code.value.replace(/\s/g, ''),
            });
            if (regis_res.token) {
                document.cookie = `user_id=${JSON.stringify(regis_res.user.id)}; path=/`;
                document.cookie = `token=${regis_res.token}; path=/`;
                document.cookie = `regis_data= ; path=/`;
                cb();
            }
            // else {
            //     error_notify()
            // }
            yield put(get_me_act({ ...regis_res.user }));
            yield put(regis_code_act({ ...regis_res, open_alert: true }));
        }
    } catch (e) {
        console.log(e);
    }
}
function* feedback(data) {
    try {
        const { cb, translate, ...rest } = data.payload;
        const new_data = {
            name: rest.name.value,
            phone_number: rest.phone_number.value,
            email: rest.email.value,
            message: rest.message.value
        };
        yield put(feedback_submitted_act());
        if (rest.name.valid && rest.phone_number.valid && rest.email.valid && rest.message.valid) {
            yield put(feedback_loading_act());
            const feedback = yield Category.feedback(new_data);
            if (feedback.id) {
                success_notify(translate.process_successful);
                cb();
            } else {
                error_notify();
            }
            yield put(feedback_act({ ...feedback, submitted: false }));
        }
    } catch (e) {
        console.log(e);
    }
}
export function* watchLoginRegis() {
    yield takeEvery(LoginRegis.LOGIN_SAGA, login);
    yield takeEvery(LoginRegis.REGIS_SAGA, regis);
    yield takeEvery(LoginRegis.REGIS_CODE_SAGA, regis_code);
    yield takeEvery(LoginRegis.FEEDBACK_SAGA, feedback);
    yield takeEvery(LoginRegis.LOGIN_WITH_PHONE_NUMBER_SAGA, loginWithPhoneNumber);
    yield takeEvery(LoginRegis.CODE_FOR_LOGIN_SAGA, codeForLogin);
}
// export function* loginSaga() {
//     yield takeEvery(LoginRegis.LOGIN_SAGA, login);
// }
// export function* regisSaga() {
//     yield takeEvery(LoginRegis.REGIS_SAGA, regis);
// }
// export function* regisCodeSaga() {
//     yield takeEvery(LoginRegis.REGIS_CODE_SAGA, regis_code);
// }
// export function* feedbackSaga() {
//     yield takeEvery(LoginRegis.FEEDBACK_SAGA, feedback);
// }

