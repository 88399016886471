import { actionChannel, call, put, takeEvery } from 'redux-saga/effects';
import { My_personal_details } from "../constants";
import {
    settings_act, settings_submitted, settings_loading, settings_input,
    settings_data, change_language
} from "../actions/my-info";
import Category from '../../services/api/my-info';
function* settings_saga(data) {
    const { add, ...rest } = data.payload;
    try {
        // yield put(settings_submitted())
        // if (data.payload.message && data.payload.message.length > 0) {
        // yield put(settings_loading())
        // const settings_res = yield !add ? Category.settings(rest) : Category.post_settings(rest);
        const settings_res = yield Category.settings(rest);
        document.cookie = `locale=${settings_res.language_details.code}; path=/`;
        yield put(settings_input({ ...data.payload, id: settings_res.id }));
        yield put(change_language({ code: settings_res.language_details.code }));
        // }
    } catch (e) {
        console.log(e);
    }
}
function* get_settings_saga(data) {
    try {
        // yield put(settings_submitted())
        // if (data.payload.message && data.payload.message.length > 0) {
        // yield put(settings_loading())
        const settings_res = yield Category.get_settings(data.payload);
        yield put(settings_data(settings_res));
        // }
    } catch (e) {
        console.log(e);
    }
}
export function* settingsSaga() {
    yield takeEvery(My_personal_details.SETTINGS_SAGA, settings_saga);
}
export function* get_SettingsSaga() {
    yield takeEvery(My_personal_details.GET_SETTINGS_SAGA, get_settings_saga);
}

