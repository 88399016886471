import { call, put, takeEvery } from 'redux-saga/effects';
import { LoginRegis } from "../constants";
import {
    forget_password_loading_act,
    code_res_act,
    phone_or_email_act,
    submitted_forget,
    submitted_code_forget
} from "../actions/login-regis";
import Category from '../../services/api/login-regis';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const success_notify = () => toast.success("success", {
    position: toast.POSITION.TOP_CENTER
})
const error_notify = (text) => toast.error(text, {
    position: toast.POSITION.TOP_CENTER
});
function* enter_phone(data) {
    try {
        const { cb, translate, ...rest } = data.payload
        const post_data = rest;
        yield put(submitted_forget())
        if (post_data.phone_number.valid) {
            yield put(forget_password_loading_act());
            const login_res = yield Category.forget_password_phone({ phone_number: post_data.phone_number.value });
            yield put(phone_or_email_act({ ...login_res.data, open_alert: true }));
            if (login_res.status === 200 || login_res.status === 201) {
                cb()
            } else {
                error_notify(translate[login_res.data.message])
            }

        }
    } catch (e) {
        console.log(e);
    }
}
function* enter_email(data) {
    try {
        const { cb, translate, ...rest } = data.payload
        const post_data = rest;
        yield put(submitted_forget())
        if (post_data.email.valid) {
            yield put(forget_password_loading_act());
            const login_res = yield Category.forget_password_email({ email: post_data.email.value });
            yield put(phone_or_email_act({ ...login_res.data, open_alert: true }));
            if (login_res.status === 200 || login_res.status === 201) {
                cb();
            } else {
                error_notify(translate[login_res.data.message])
            }
        }
    } catch (e) {
        console.log(e);
    }
}
function* chack_phone_code(data) {
    try {
        const { cb, ...post_data } = data.payload;
        yield put(submitted_code_forget());
        if (post_data.confirm_code.valid) {
            yield put(forget_password_loading_act());
            const login_res = yield Category.forget_password_phone_code({
                phone_number: post_data.phone_number,
                confirm_code: post_data.confirm_code.value.replace(/\s/g, ''),
                send_password: post_data.send_password
            });
            if (login_res.data.message === "OK") {
                cb()
            }
            //  else {
            //     error_notify(login_res.data.message)
            // }
            yield put(code_res_act({ ...login_res.data, open_alert: true }));
        }
    } catch (e) {
        console.log(e);
    }
}
function* chack_email_code(data) {
    try {
        const { cb, ...post_data } = data.payload;
        yield put(submitted_code_forget());
        if (post_data.confirm_code.valid) {
            yield put(forget_password_loading_act());
            const login_res = yield Category.forget_password_email_code({
                email: post_data.email,
                confirm_code: post_data.confirm_code.value.replace(/\s/g, ''),
                send_password: post_data.send_password

            });
            if (login_res.data.message === "OK") {
                cb()
            }
            yield put(code_res_act({ ...login_res.data, open_alert: true }));
        }
    } catch (e) {
        console.log(e);
    }
}
export function* enterPhoneSaga() {
    yield takeEvery(LoginRegis.FORGET_PASSWORD_PHONE_SAGA, enter_phone);
}
export function* enterEmailSaga() {
    yield takeEvery(LoginRegis.FORGET_PASSWORD_EMAIL_SAGA, enter_email);
}
export function* enterPhoneCodeSaga() {
    yield takeEvery(LoginRegis.FORGET_PASSWORD_PHONE_CODE_SAGA, chack_phone_code);
}
export function* enterEmailCodeSaga() {
    yield takeEvery(LoginRegis.FORGET_PASSWORD_EMAIL_CODE_SAGA, chack_email_code);
}

