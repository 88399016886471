import LoginHooks from "../../../hooks/loginHook";
import { LoginRegis } from "../../constants";
const Login = (state = {
    loading: false,
    data: {
        "role_code": "",
        "username": "",
        "password": ""
    }
}, action) => {
    switch (action.type) {
        case LoginRegis.LOGIN_SUBMITTED:
            return {
                ...state,
                submitted: true
            }
        case LoginRegis.LOGIN_UMOUNT:
            let data = {
                ...state,
                loading: false,
                submitted: false,
            }
            return data
        case LoginRegis.LOGIN_LOADING:
            return {
                ...state,
                loading: true,
            }
        case LoginRegis.LOGIN:
            return {
                ...state,
                ...action.payload,
                loading: action.payload.status ? false : true,
                submitted: false
            }
        case LoginRegis.CLOSE_ALERT:
            return {
                ...state,
                open_alert: false
            }
        default:
            state;
    }
    return state
}
export default Login;