import { General } from "../constants";
const Language = (state = {}, action) => {
    switch (action.type) {
        case General.GET_LENGUAGE:
            return {
                ...action.payload
            }
        default:
            state;
    }
    return state
}
export default Language;