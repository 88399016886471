import authApi, { setApiAuthorizationHeader } from "../authinstance";
import keys from "../keys";
const queryString = require('query-string');

class Auth {
    async get_card(inf) {
        try {
            setApiAuthorizationHeader(inf.token)
            const response = await authApi.get(`${keys.SERVER_URL}schedule/get-cards/`);
            return response.data
        } catch (error) {
            return error.response
        }
    }
    async create_card(inf) {
        try {
            setApiAuthorizationHeader(inf.token)
            const response = await authApi.post(`${keys.SERVER_URL}schedule/create-card/?rurl=https://beautycils.app/payment-cards`, inf.data);
            return response.data
        } catch (error) {
            return error.response
        }
    }
    async delete_card(data) {
        const { token, ...params } = data;
        const query = queryString.stringify(params);
        try {
            setApiAuthorizationHeader(token)
            const response = await authApi.delete(`${keys.SERVER_URL}/schedule/remove-card/?${query}`);
            return response.data
        } catch (error) {
            return error.response
        }
    }
    async create_account(token) {
        try {
            setApiAuthorizationHeader(token)
            const response = await authApi.get(`${keys.SERVER_URL}schedule/stripe-login/`);
            return response.data
        } catch (error) {
            return error.response
        }
    }
    async get_history(data) {
        const { token, id, ...params } = data;
        const query = queryString.stringify(params);
        try {
            setApiAuthorizationHeader(token)
            const response = await authApi.get(`${keys.SERVER_URL}schedule/transaction/?client=${id}&${query}`);
            return response.data
        } catch (error) {
            return error.response
        }
    }
}
const category = new Auth();
export default category;
