import { General } from "../constants";
const Gender = (state = {
    loading: true,
    gender: {}
}, action) => {
    switch (action.type) {
        case General.GET_GENDER:
            return {
                ...state,
                gender: { ...action.payload },
                loading: false
            }
        default:
            state;
    }
    return state
}
export default Gender;