import { Note } from "../constants";
const NoteRedus = (state = {
    loading: true,
    archive: 0,
    review: {
        comment: "",
        rating: null
    },
    order_days: []
}, action) => {
    switch (action.type) {
        case Note.MY_NOTE_LOADING:
            return {
                loading: true,
                archive: state.archive,
                order_days: state.order_days ? state.order_days : []
            };
        case Note.SET_CLIENT_ORDER_DAYS:
            return {
                ...state,
                order_days: action.payload
            };

        case Note.MY_NOTE:
            const { notes, type, ...rest } = action.payload;
            const new_state = notes.status && (notes.status === 500) ? {
                loading: false,
                archive: type === "archived" ? 1 : 0,
                review: {
                    comment: "",
                    rating: null
                }
            } : {
                ...state,
                ...notes,
                archive: type === "archived" ? 1 : 0,
                loading: false,
                ...rest,
                order_days: state.order_days ? state.order_days : []
            };
            return new_state;

        case Note.CANCEL_COMMENT_LOADING:
            return {
                ...state,
                comment_loading: true,
            };
        case Note.COMMENT:
            return {
                ...state,
                cancel_reason: action.payload
            };
        case Note.CANCEL_NOTE:
            return {
                ...state,
                ...action.payload,
                comment_loading: false,
                cancel_reason: null
            };
        case Note.REVIEW:
            return {
                ...state,
                review: {
                    ...state.review,
                    [action.payload.key]: action.payload.value
                }
            };
        case Note.ADD_REVIEW:
            return {
                review_loading: action.payload.id ? true : false,
                ...state,
                ...action.payload,
            };
        case Note.ADD_REVIEW_LOADING:
            return {
                ...state,
                review_loading: true,
            };
        case Note.DELETE_REVIEW:
            let review = {
                ...state,
                review: {
                    comment: "",
                    rating: null
                }
            };
            return review;
        case Note.SET_MASTER_ID:
            return {
                ...state,
                master_id: action.payload,
            };
        case Note.CLOSE_ALERT:
            return {
                ...state,
                open_alert: "",
            };
        default:
            state;
    }
    return state;
};
export default NoteRedus;