import { My_personal_details } from "../../constants";
const changeEmail = (state = {
    loading: false,
    submitted: false,
    data: {
        "phone_number": { value: "", valid: false },
        "confirm_code": { value: "", valid: false }
    }
}, action) => {
    switch (action.type) {
        case My_personal_details.SET_PHONE:
            let data = {
                ...state.data,
                ...action.payload
            }

            return {
                ...state,
                data
            }
        case My_personal_details.EDIT_MY_PHONE:
            return {
                ...state,
                submitted: false,
                loading: false
            }
        case My_personal_details.SET_PHONE_INPUT_VALUES:
            let edit_data = {
                ...state.data,
                [action.payload.label]: action.payload.data
            }
            return {
                ...state,
                data: edit_data
            }
        case My_personal_details.PHONE_SUBMITTED:
            return {
                ...state,
                submitted: true
            }
        case My_personal_details.CHACK_PHONE_SUBMITTED:
            return {
                ...state,
                code_submitted: true
            }
        case My_personal_details.PHONE_LOADING:
            return {
                ...state,
                loading: true
            }
        default:
            state;
    }
    return state
}
export default changeEmail;