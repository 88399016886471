import { Master } from "../../constants";
const MasterFilter = (state = {
    loading: true,
    open_modal: false,
    params: { radius: 15, ordering: "popularity", search: "" }
}, action) => {
    switch (action.type) {
        case Master.OPEN_MODAL:
            return {
                ...state,
                open_modal: true
            };
        case Master.CLOSE_MODAL:
            return {
                ...state,
                open_modal: false
            };
        case Master.MASTER_FILTER:
            return {
                ...state,
                ...action.payload,
                loading: false
            };
        case Master.MASTER_PARAMS:
            if (action?.payload.key === "sub_category") {
                // let selected_index;
                // let same = false;
                // let value = state.params.service ? state.params.service.split(",") : [];
                let service_name = action.payload.name;
                // for (let index = 0; index < value.length; index++) {
                //     const element = value[index];
                //     if (+element === action.payload.value) {
                //         selected_index = index;
                //         same = true;
                //     }
                // }
                // if (!same) {
                //     value.push(action.payload.value);
                //     service_name_list.push(action.payload.name);
                // } else {
                //     value.splice(selected_index, 1);
                //     service_name_list.splice(selected_index, 1);
                // }
                return {
                    ...state,
                    service_name,
                    service_slug: action.payload.slug,
                    params: {
                        ...state.params,
                        sub_category: action.payload.value,
                    }
                };
            }
            return {
                ...state,
                service_name_list: [],
                params: {
                    ...state.params,
                    ...action.payload
                }
            };
        default:
            state;
    }
    return state;
};
export default MasterFilter;