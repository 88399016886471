import { createStore, applyMiddleware, compose } from 'redux';
import { createWrapper } from 'next-redux-wrapper';
import combineReducers from './reducers';
import createSagaMiddleware from 'redux-saga';
import rootSaga from './sagas';
import { HYDRATE } from 'next-redux-wrapper';

const reducer = (state, action) => {
    if (action.type === HYDRATE) {
        const nextState = {
            ...state, // use previous state
            ...action.payload, // apply delta from hydration
        }
        if (state.count) nextState.count = state.count // preserve count value on client side navigation
        return nextState
    } else {
        return combineReducers(state, action)
    }
}

const logger = store => next => action => {
    let result = next(action)
    return result
}


export const makeStore = (context) => {
    // 1: Create the middleware
    const sagaMiddleware = createSagaMiddleware();

    // 2: Add an extra parameter for applying middleware:
    const composeEnhancers =
        typeof window === 'object' &&
            window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
            window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
                // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
            }) : compose;

    const enhancer = composeEnhancers(
        applyMiddleware(sagaMiddleware),
        // other store enhancers if any
    );
    const store = createStore(reducer, enhancer);

    // 3: Run your sagas on server
    store.sagaTask = sagaMiddleware.run(rootSaga);
    // 4: now return the store:
    return store;
};
export const wrapper = createWrapper(makeStore);
// sagaMiddleware.run(rootSaga)