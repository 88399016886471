import { Posts } from "../constants";
const PostComments = (state = { disabled: false }, action) => {
    if (action.type === Posts.POSTS_COMMENT_LOADING) {
        let list = state[action.payload.post_id]
            ? [...state[action.payload.post_id].results] : [];
        let data = {
            ...state,
            [action.payload.post_id]: {
                results: list,
                more_loading: true,
                see_more: true,
                loading: action.payload.more_loading
                    ? false
                    : true,
                more_loading: action.payload.more_loading
            }
        };
        return data;
    }
    if (action.type === Posts.POSTS_COMMENTS) {
        let data = { ...state };
        if (action.payload) {
            let id = action.payload?.results[0]?.post;
            let list = state[id]
                ? [...state[id]?.results, ...action.payload?.results]
                : action.payload?.results;
            if (list[1] && list[0]?.id === list[1]?.id) {
                list.shift();
            }
            data = {
                ...state,
                [id]: {
                    count: action.payload.count,
                    results: list,
                    more_loading: false,
                    see_more: false,
                    loading: false,
                }
            };
        }
        return data;
    }
    if (action.type === Posts.ADD_COMMENT_LOADING) {
        return {
            ...state,
            disabled: true
        };
    }
    if (action.type === Posts.ADD_COMMENT) {
        let data = { ...state };
        if (action.payload) {
            data = { ...data };
            if (action.payload.parent) {
                for (let index = 0; index < data[action.payload.post].results.length; index++) {
                    const element = data[action.payload.post].results[index];
                    if (element.id === action.payload.parent) {
                        element.child_comments.push(action.payload);
                    }
                }
            } else {
                let list = data[action.payload.post] ? data[action.payload.post].results : [];
                list.unshift(action.payload);
                data = { ...data, [action.payload.post]: { results: list }, disabled: false };
            }
            data[action.payload.post].new_comment_value = "";
            data.disabled = false;
        }
        return data;
    }
    if (action.type === Posts.NEW_COMMENT_VALUE) {
        let data = { ...state };
        if (action.payload) {
            data[action.payload.post].new_comment_value = action.payload.comment;
        }
        return data;
    }
    return state;
};
export default PostComments;