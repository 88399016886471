import { My_personal_details } from "../../constants";
const Help = (state = {
    submitted: false,

}, action) => {
    switch (action.type) {
        case My_personal_details.HELP_SUBMITTED:
            return {
                ...state,
                submitted: true,
            }
        case My_personal_details.HELP:
            return {
                ...state,
                loading: false,
                submitted: false,
                text: ""
            }
        case My_personal_details.HELP_LOADING:
            return {
                ...state,
                loading: true,
            }
        case My_personal_details.HELP_INPUT:
            return {
                ...state,
                text: action.payload
            }
        default:
            state;
    }
    return state
}
export default Help;