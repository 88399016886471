import { My_personal_details } from "../../constants";
const getme = (state = {
}, action) => {
    switch (action.type) {
        case My_personal_details.GET_ME:
            if (action.payload) {
                return {
                    ...state,
                    data: {
                        ...state.data,
                        ...action.payload
                    }
                }
            }
        default:
            state;
    }
    return state
}
export default getme;