import { Master } from "../../constants";
const MasterData = (state = {
    loading: true,
    data: {}
}, action) => {
    switch (action.type) {
        case Master.ADD_GO_HOME_PRICE:
            let service = [...state.data.master_services];
            for (let index = 0; index < service.length; index++) {
                const element = service[index];
                if (element.id === action.payload) {
                    element.go_home_selected = element.go_home_selected ? (!element.go_home_selected) : true;
                }
            }
            return {
                ...state,
                data: {
                    ...state.data,
                    master_services: service
                },

            };
        case Master.GET_MASTER_DATA:
            if (action.payload) {
                return {
                    ...state,
                    data: { ...state.data, ...action.payload },
                    loading: false,
                    service_loading: false
                };
            } else {
                return {
                    ...state
                };
            }
        case Master.SERVICE_LOADER:
            return {
                ...state,
                service_loading: true
            };
        case Master.COMMENT:
            return {
                ...state,
                comment: action.payload
            };
        case Master.ADD_DATA:
            return {
                ...state,
                [action.payload.key]: action.payload.data
            };
        case Master.CREATE_ORDER_LOADING:
            return {
                ...state,
                order_loading: !state.order_loading
            };
        case Master.CREATE_ORDER:
            return {
                ...state,
                comment: "",
                order: action.payload,
            };
        default:
            state;
    }
    return state;
};
export default MasterData;