import authApi, { setApiAuthorizationHeader } from "../authinstance";
import keys from "../keys";
class Auth {
    async edit_my_personal_details(data) {
        try {
            setApiAuthorizationHeader(data.token)
            const response = await authApi.put(`${keys.SERVER_URL}userdetails/edit-my-personal-details/`, data.rest);
            return response.data
        } catch (error) {
            return error.response
        }
    }
    async get_me_client(token) {
        try {
            setApiAuthorizationHeader(token)
            const response = await authApi.get(`${keys.SERVER_URL}userdetails/get-me-client/`);
            return response.data
        } catch (error) {
            return error.response
        }
    }
    async help(data) {
        try {
            // setApiAuthorizationHeader(data.token)
            const response = await authApi.post(`${keys.SERVER_URL}userdetails/help-message/`, data);
            return response.data
        } catch (error) {
            return error.response
        }
    }
    async settings(data) {
        const { id, ...put_data } = data;
        try {
            // setApiAuthorizationHeader(data.token)
            const response = await authApi.put(`${keys.SERVER_URL}userdetails/edit-user-settings/`, put_data);
            return response.data
        } catch (error) {
            return error.response
        }
    }
    async post_settings(data) {
        try {
            // setApiAuthorizationHeader(data.token)]

            const response = await authApi.put(`${keys.SERVER_URL}userdetails/edit-user-settings/`, data);
            return response.data
        } catch (error) {
            return error.response
        }
    }
    async get_settings(id) {
        try {
            // setApiAuthorizationHeader(data.token)
            const response = await authApi.get(`${keys.SERVER_URL}userdetails/settings/?user_id=${id}`,);
            return response.data
        } catch (error) {
            return error.response
        }
    }
}
const category = new Auth();
export default category;
