import authApi, { setApiAuthorizationHeader } from "../authinstance";
import keys from "../keys";
const queryString = require('query-string');
class Auth {
    async get_note(inf) {
        const { token, user_id, ...rest } = inf;
        let query = queryString.stringify(rest);
        try {
            setApiAuthorizationHeader(token);
            const response = await authApi.get(`${keys.SERVER_URL}schedule/get-client-orders/${user_id}/?${query}`);
            return response.data;
        } catch (error) {
            return error.response;
        }
    }
    async get_note_by_id(inf) {
        const { token, id, } = inf;
        try {
            setApiAuthorizationHeader(token);
            const response = await authApi.get(`${keys.SERVER_URL}schedule/get-client-orders/${user_id}/${id}`);
            return response.data;
        } catch (error) {
            return error.response;
        }
    }
    async get_client_order_days(inf) {
        const { token, user_id, ...rest } = inf;
        let query = queryString.stringify(rest);
        try {
            setApiAuthorizationHeader(token);
            const response = await authApi.get(`${keys.SERVER_URL}/schedule/get-client-order-days/${user_id}/?${query}`);
            return response.data;
        } catch (error) {
            return error.response;
        }
    }
    async cancel_note(inf) {
        const { token, id, ...rest } = inf;
        try {
            setApiAuthorizationHeader(token);
            const response = await authApi.post(`${keys.SERVER_URL}schedule/cancel-order/${id}/`, rest);
            return response;
        } catch (error) {
            return error.response;
        }
    }
    async add_review(inf) {
        const { token, ...rest } = inf;
        try {
            setApiAuthorizationHeader(token);
            const response = await authApi.post(`${keys.SERVER_URL}/timeline/review/`, rest);
            // /schedule/check-payment/+redirect_url+/
            return response;
        } catch (error) {
            return error.response;
        }
    }
    async without_review(inf) {
        const { token, ...rest } = inf;
        try {
            setApiAuthorizationHeader(token);
            const response = await authApi.get(`${keys.SERVER_URL}schedule/without-review/`, rest);
            // /schedule/check-payment/+redirect_url+/
            return response;
        } catch (error) {
            return error.response;
        }
    }
    async with_review(inf) {
        const { token, ...rest } = inf;
        try {
            setApiAuthorizationHeader(token);
            const response = await authApi.get(`${keys.SERVER_URL}schedule/with-review/`, rest);
            // /schedule/check-payment/+redirect_url+/
            return response;
        } catch (error) {
            return error.response;
        }
    }
    async pay_for_order(inf) {
        const { token, id, ...rest } = inf;
        try {
            setApiAuthorizationHeader(token);
            const response = await authApi.post(`${keys.SERVER_URL}schedule/pay-for-order/${id}/?rurl=https://beautycils.app`, rest);
            if (response.data.redirect_url) {
                const res = await authApi.get(`${keys.SERVER_URL}/schedule/check-full-payment/${response.data.redirect_url}`);
                return res;
            } else { 
                return response.data;
            }
        } catch (error) {
            return error.response;
        }
    }
    async pay_for_order_without_add_card(inf) {
        const { token, id, ...rest } = inf;
        try {
            setApiAuthorizationHeader(token);
            const response = await authApi.post(`${keys.SERVER_URL}pay_for_order_without_add_card`, rest);
            // if (response.data.redirect_url) {
            //     const res = await authApi.get(`${keys.SERVER_URL}/schedule/check-full-payment/${response.data.redirect_url}`);
            //     return res;
            // } else {
                return response.data;
            // }
        } catch (error) {
            return error.response;
        }
    }
}
const category = new Auth();
export default category;
