import { General } from "../constants";
const Country = (state = {
    loading: true,
    country: {}
}, action) => {
    switch (action.type) {
        case General.GET_COUNTRY:
            return {
                ...state,
                country: { ...action.payload },
                loading: false
            }
        default:
            state;
    }
    return state
}
export default Country;