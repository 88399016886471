import { Master } from "../../constants";
const MasterPostFilters = (state = {
    loading: true,
}, action) => {
    switch (action.type) {
        case Master.POST_FILES:
            let results = action.payload?.results
                ? (state.results
                    ? [...state.results, ...action.payload?.results]
                    : action.payload?.results)
                : [];
            let new_data = {
                ...state,
                ...action.payload,
                results,
                loading: false
            };
            return new_data;
        default:
            state;
    }
    return state;
};
export default MasterPostFilters;