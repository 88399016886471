import { show_code } from "../../actions/login-regis";
import { LoginRegis } from "../../constants";
const Regis = (state = {
    loading: false,
    form: {
        role_code: { value: "CL", valid: false },
        username: { value: "", valid: false },
        phone_number: { value: "", valid: false },
        email: { value: "", valid: false },
        password: { value: "", valid: false },
        confirm_password: { value: "", valid: false },
        confirm_code: { value: "", valid: false }
    }
}, action) => {
    switch (action.type) {
        case LoginRegis.SET_REGIS_INPUT_VALUE:
            return {
                form: {
                    ...state.form,
                    [action.payload.label]: action.payload.data
                }
            }
        case LoginRegis.REGIS_SUBMITTED:
            return {
                ...state,
                submitted: true
            }
        case LoginRegis.REGIS_LOADING:
            return {
                ...state,
                loading: true,
                form: {
                    ...state.form,
                    confirm_code: { value: "", valid: false }
                }
            }
        case LoginRegis.REGIS_CODE_LOADING:
            return {
                ...state,
                code_loading: true,
            }
        case LoginRegis.REGIS:
            return {
                ...state,
                ...action.payload,
                loading: false,
                show_code: true
            }
        case LoginRegis.SHOW_CODE:
            let show_data = {
                ...state,
                show_code: false
            }
            return show_data
        case LoginRegis.REGIS_CODE:
            const { data, ...rest } = action.payload;
            const new_data = {
                ...state,
                ...action.payload,
                data: {
                    ...data,
                    ...state.data
                },
                ...rest,
                code_loading: rest.token ? true : false,
                show_code: true
            }
            return new_data
        case LoginRegis.REGIS_CODE_SUBMITTED:
            return {
                ...state,
                submitted_code: true
            }
        case LoginRegis.CLOSE_REGIS_ALERT:
            return {
                ...state,
                open_alert: false
            }
        default:
            state;
    }
    return state
}
export default Regis;