import { Card } from "../../constants";
const History = (state = {
    loading: false,
}, action) => {
    switch (action.type) {
        case Card.GET_HISTORY:
            return {
                ...state,
                loading: false,
                ...action.payload
            }
        case Card.HISTORY_LOADING:
            return {
                ...state,
                loading: true
            }
        default:
            state;
    }
    return state
}
export default History;