import { LoginRegis } from "../../constants";
const FeedBack = (state = {
    loading: false,
}, action) => {
    switch (action.type) {
        case LoginRegis.FEEDBACK_SUBMITTED:
            return {
                ...state,
                submitted: true
            }
        case LoginRegis.FEEDBACK_LOADING:
            return {
                ...state,
                loading: true,
            }
        case LoginRegis.FEEDBACK:
            return {
                ...state,
                ...action.payload,
                loading: false
            }
        default:
            state;
    }
    return state
}
export default FeedBack;