import { Note } from "../constants";
export const my_note = (data) => {
    return ({
        type: Note.MY_NOTE,
        payload: data
    });
};
export const delete_review = () => {
    return ({
        type: Note.DELETE_REVIEW
    });
};
export const my_note_loading = (data) => {
    return ({
        type: Note.MY_NOTE_LOADING,
        payload: data
    });
};
export const my_note_saga = (data) => {
    return ({
        type: Note.MY_NOTE_SAGA,
        payload: data
    });
};
export const get_client_order_days_saga = (data) => {
    return ({
        type: Note.GET_CLIENT_ORDER_DAYS_SAGA,
        payload: data
    });
};
export const set_client_order_days = (data) => {
    return ({
        type: Note.SET_CLIENT_ORDER_DAYS,
        payload: data
    });
};
export const cancel_note = (data) => {
    return ({
        type: Note.CANCEL_NOTE,
        payload: data
    });
};
export const cancel_note_loading = (data) => {
    return ({
        type: Note.CANCEL_COMMENT_LOADING,
        payload: data
    });
};
export const cancel_note_comment = (data) => {
    return ({
        type: Note.COMMENT,
        payload: data
    });
};
export const cancel_note_saga = (data) => {
    return ({
        type: Note.CANCEL_COMMENT_SAGA,
        payload: data
    });
};
export const add_review_saga = (data) => {
    return ({
        type: Note.ADD_REVIEW_SAGA,
        payload: data
    });
};
export const add_review = (data) => {
    return ({
        type: Note.ADD_REVIEW,
        payload: data
    });
};
export const order_review = (data) => {
    return ({
        type: Note.REVIEW,
        payload: data
    });
};
export const add_review_loading = () => {
    return ({
        type: Note.ADD_REVIEW_LOADING,
    });
};
export const master_id = (data) => {
    return ({
        type: Note.SET_MASTER_ID,
        payload: data
    });
};
export const close_alert = () => {
    return ({
        type: Note.CLOSE_ALERT,
    });
};
export const pay_for_order = (data) => {
    return ({
        type: Note.PAY_FOR_ORDER,
        payload: data
    });
};
export const pay_for_order_saga = (data) => {
    return ({
        type: Note.PAY_FOR_ORDER_SAGA,
        payload: data
    });
};
export const pay_for_order_loading = () => {
    return ({
        type: Note.PAY_FOR_ORDER_LOADING,
        payload: data
    });
}
export const pay_without_add_card = (data) => {
    return ({
        type: Note.PAY_WITHOUT_ADD_CARD_SAGA,
        payload: data
    });
}

