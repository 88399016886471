import { Master } from "../constants";
const closestCities = (state =[], action) => {
    switch (action.type) {
        case Master.SET_CLOSEST_CITIES:
            return action.payload
        default:
            state;
    }
    return state
}
export default closestCities;