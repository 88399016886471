import authApi, { setApiAuthorizationHeader } from "../authinstance";
import keys from "../keys";
class Auth {
    async login(data) {
        try {
            const response = await authApi.post(`${keys.SERVER_URL}userdetails/login-user/`, data);
            return response.data
        } catch (error) {
            return error.response
        }
    }
    async login_with_phone_number(data) {
        try {
            const response = await authApi.post(`${keys.SERVER_URL}userdetails/send-login-code/`, data);
            return response.data
        } catch (error) {
            return error.response
        }
    }
    async enter_code(data) {
        try {
            const response = await authApi.post(`${keys.SERVER_URL}userdetails/login-with-code/`, data);
            return response.data
        } catch (error) {
            return error.response
        }
    }
    async registration(data) {
        try {
            const response = await authApi.post(`${keys.SERVER_URL}/userdetails/send-registration-code/`, data);
            return response.data
        } catch (error) {
            return error.response
        }
    }
    async registration_code(data) {
        try {
            const response = await authApi.post(`${keys.SERVER_URL}userdetails/register-user/`, data);
            return response.data
        } catch (error) {
            return error.response
        }
    }
    async forget_password_phone(data) {
        try {
            const response = await authApi.post(`${keys.SERVER_URL}userdetails/send-forget-code-phone/`, data);
            return response
        } catch (error) {
            return error.response
        }
    }
    async forget_password_email(data) {
        try {
            const response = await authApi.post(`${keys.SERVER_URL}userdetails/send-forget-code-email/`, data);
            return response
        } catch (error) {
            return error.response
        }
    }
    async forget_password_phone_code(data) {
        try {
            const response = await authApi.post(`${keys.SERVER_URL}userdetails/check-forget-code-phone/`, data);
            return response
        } catch (error) {
            return error.response
        }
    }
    async forget_password_email_code(data) {
        try {
            const response = await authApi.post(`${keys.SERVER_URL}userdetails/check-forget-code-email/`, data);
            return response
        } catch (error) {
            return error.response
        }
    }
    async feedback(data) {
        try {
            const response = await authApi.post(`${keys.SERVER_URL}utils/feedback/`, data);
            return response.data
        } catch (error) {
            return error.response
        }
    }
    async about_us() {
        try {
            const response = await authApi.get(`${keys.SERVER_URL}utils/about-us/`);
            return response.data
        } catch (error) {
            return error.response
        }
    }
}
const category = new Auth();
export default category;
