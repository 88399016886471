import { Service } from "../../constants";
const Masters = (state = {
    loading: false,
    data: {
        page: 1,
        results: []
    }
}, action) => {
    switch (action.type) {
        case Service.GET_MASTER_LOADING:
            return {
                ...state,
                loading: !state.loading
            };
        case Service.GET_MASTER:
            return {
                ...state,
                data: { ...action.payload },
                // loading: false
            };
        case Service.ADD_FAVORITE_MASTERS:
            let new_list = [...state.data.results];
            for (let index = 0; index < new_list.length; index++) {
                const element = new_list[index];
                if (element.id === action.payload) {
                    element.is_favorite = !element.is_favorite;
                }
            }
            return {
                ...state,
                data: { ...state.data, results: new_list },
            };
        default:
            state;
    }
    return state;
};
export default Masters;