import { combineReducers } from 'redux';
import posts from "./posts";
import comments from "./postComments";
import postData from "./postData";
import login from "./login-regis/login";
import regis from "./login-regis/regis";
import forgetPassword from "./login-regis/forgetPassword";
import edit from "./myinfo/edit";
import city from "./city";
import country from "./country";
import gender from "./gender";
import help from "./myinfo/help";
import settings from "./myinfo/settings";
import getme from "./myinfo/getme";
import language from "./language";
import service from "./service";
import masters from "./masters/masters";
import selected_master from "./masters/selected-master";
import master_data from "./masters/master-data";
import master_schedule from "./masters/master-schedule";
import post_files from "./masters/master-post-files";
import review from "./masters/review";
import master_filters from "./masters/master-filters";
import feedback from "./login-regis/feedback";
import popularMaster from "./popularMaster";
import card_list from "./card/card-list";
import history from "./card/history";
import note from "./note";
import change_email from "./myinfo/changeEmail";
import change_phone from "./myinfo/changePhone";
import translate from './translate';
import reviewRedus from './review';
import closestCities from './closestCities';
import closestMastersPosts from './closestMastersPosts';
import menu from './menu';
import postsSlider from './postsSlider';
import helpInfo from './help';
const combineReduc = combineReducers({
    posts,
    comments,
    postData,
    login,
    regis,
    feedback,
    forgetPassword,
    edit,
    country,
    city,
    gender,
    help,
    language,
    settings,
    getme,
    service,
    masters,
    master_schedule,
    selected_master,
    master_data,
    post_files,
    review,
    master_filters,
    popularMaster,
    card_list,
    history,
    note,
    change_email,
    change_phone,
    translate,
    reviewRedus,
    closestCities,
    closestMastersPosts,
    menu,
    postsSlider,
    helpInfo
});
export default combineReduc;