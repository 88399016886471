import { Card } from "../../constants";
const CardList = (state = {
    loading: false,
    form: {
        "number": {
            value: "",
        },
        "exp_month": {
            value: "",
        },
        "exp_year": {
            value: "",
        },
        "cvc": {
            value: "",
        },
        "name": {
            value: "",
        },
        "email": {
            value: "",
        },
        "phone_number": {
            valid: true,
            value: '+33'
        }
    }
}, action) => {
    switch (action.type) {
        case Card.CARD_LOADING:
            return {
                ...state,
                loading: true
            };
        case Card.CREATE_CARD_LOADING:
            return {
                ...state,
                create_loading: true
            };
        case Card.GET_CARD:
            let data = !action.payload.data.message ? action.payload.data : [];
            let login_stripe = action.payload.data.message === "Something went wrong" ? "login_stripe" : "";
            return {
                ...state,
                data,
                login_stripe,
                loading: false
            };
        case Card.ADD_CARD:
            return {
                ...state,
                ...action.payload,
                form: {
                    "number": {
                        value: "",
                    },
                    "exp_month": {
                        value: "",
                    },
                    "exp_year": {
                        value: "",
                    },
                    "cvc": {
                        value: "",
                    },
                    "name": {
                        value: "",
                    },
                    "email": {
                        value: "",
                    },
                    "phone_number": {
                        value: "",
                    }
                },
                create_loading: false,
                submitted: false
            };
        case Card.CARD_INPUTS_VALUE:
            return {
                ...state,
                form: {
                    ...state.form,
                    [action.payload.key]: action.payload.value
                }
            };
        case Card.SUBMITTED:
            return {
                ...state,
                submitted: true,
            };
        default:
            state;
    }
    return state;
};
export default CardList;