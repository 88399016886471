import { call, put, takeEvery } from 'redux-saga/effects';
import { General } from "../constants";
import {
    get_city, get_country, get_gender, get_language, city_loading, set_menu
} from "../actions/general";
import Category from '../../services/api/general';
function* get_country_saga(data) {
    try {
        const country = yield Category.get_country(data.payload);
        const gender = yield Category.get_gender(data.payload);
        if (data.payload.country) {
            const city = yield Category.get_city(data.payload);
            yield put(get_city({ ...city }));
        }
        yield put(get_gender({ ...gender }));
        yield put(get_country(country));
    } catch (e) {
        console.log(e);
    }
}
function* get_city_saga(data) {
    try {
        yield put(city_loading())
        const city = yield Category.get_city(data.payload);
        yield put(get_city({ ...city, ...data.payload }));

    } catch (e) {
        console.log(e);
    }
}
function* get_gender_saga(data) {
    try {
        const gender = yield Category.get_gender(data.payload);
        yield put(get_gender({ ...gender }));
    } catch (e) {
        console.log(e);
    }
}
function* get_language_saga(data) {
    try {
        const language = yield Category.get_language(data.payload);
        yield put(get_language({ ...language }));
    } catch (e) {
        console.log(e);
    }
}
function* get_menu(data) {
    try {
        const language = yield Category.get_menu(data.payload);
        yield put(set_menu({ ...language }));
    } catch (e) {
        console.log(e);
    }
}
export function* getCountrySaga() {
    yield takeEvery(General.GET_COUNTRY_SAGA, get_country_saga);
}
export function* getCitySaga() {
    yield takeEvery(General.GET_CITY_SAGA, get_city_saga);
}
export function* getGenderSaga() {
    yield takeEvery(General.GET_GENDER_SAGA, get_gender_saga);
}
export function* getMenuSaga() {
    yield takeEvery(General.GET_MENU, get_menu);
}
export function* getLanguageSaga() {
    yield takeEvery(General.GET_LENGUAGE_SAGA, get_language_saga);
}

