import { all } from 'redux-saga/effects';
import { postSaga, 
    CommentsSaga, 
    LikeOrDislikeSaga, 
    AddCommentSaga, 
    CommentDataSaga, 
    hidePostSaga, 
    postReportSaga, 
    addViewSaga, 
    articlesSaga,
    helpSaga
} from './postSaga';
import { watchLoginRegis } from "./LoginRegisSaga";
import { enterPhoneSaga, enterPhoneCodeSaga, enterEmailSaga, enterEmailCodeSaga } from "./forgetPasswordSaga";
import { editSaga, getMeSaga, getFilesSaga, editEmailSaga, chackEmailSaga, editPhoneSaga, chackPhoneSaga, footerSaga, editPasswordSaga, setPasswordSaga } from "./myInfoSaga";
import { getCountrySaga, getCitySaga, getGenderSaga, getLanguageSaga, getMenuSaga } from "./generalSaga";
// import { helpSaga } from "./helpSaga";
import { settingsSaga, get_SettingsSaga } from "./settingsSaga";
import {
    serviceSaga, masterSaga, selectedMasterSaga,
    masterDataSaga, postFilesSaga, reviewSaga, addFavoriteMastersSaga, removeFavoriteMastersSaga,
    masterFiltersSaga, masterScheduleSaga, createOrderSaga, availableSaga, getServiceByAddress, 
    citySaga, sendNewOrderCodeSaga, getClosestCities, getClosestMastersPosts
} from "./serviceSaga";
import { getCard, createAccount, addCard, getHistory, deleteCard } from "./cardSaga";
import { noteSaga, cancelNoteSaga, addReviewSaga, payForOrderSaga, getClientOrderDaysSaga, payForOrderWithoutAddCard } from "./noteSaga";
import { getFile, get_room } from "./chatSaga";
import { getReview } from "./reviewSaga";
export default function* rootSaga() {
    yield all([
        articlesSaga(),
        footerSaga(),
        postSaga(),
        addViewSaga(),
        CommentsSaga(),
        LikeOrDislikeSaga(),
        AddCommentSaga(),
        CommentDataSaga(),
        hidePostSaga(),
        postReportSaga(),
        availableSaga(),
        getMenuSaga(),
        // helpSaga(),
        // popularMastersSaga(),
        watchLoginRegis(),
        enterPhoneSaga(),
        enterPhoneCodeSaga(),
        enterEmailSaga(),
        enterEmailCodeSaga(),
        editSaga(),
        getMeSaga(),
        getCountrySaga(),
        getCitySaga(),
        getGenderSaga(),
        getFilesSaga(),
        editEmailSaga(),
        chackEmailSaga(),
        editPhoneSaga(),
        chackPhoneSaga(),
        editPasswordSaga(),
        setPasswordSaga(),
        getLanguageSaga(),
        helpSaga(),
        settingsSaga(),
        get_SettingsSaga(),
        serviceSaga(),
        masterSaga(),
        addFavoriteMastersSaga(),
        removeFavoriteMastersSaga(),
        selectedMasterSaga(),
        masterDataSaga(),
        postFilesSaga(),
        reviewSaga(),
        masterFiltersSaga(),
        masterScheduleSaga(),
        createOrderSaga(),
        getCard(),
        createAccount(),
        addCard(),
        deleteCard(),
        getHistory(),
        noteSaga(),
        cancelNoteSaga(),
        addReviewSaga(),
        payForOrderSaga(),
        payForOrderWithoutAddCard(),
        getFile(),
        get_room(),
        getServiceByAddress(),
        getClosestCities(),
        getClosestMastersPosts(),
        getClientOrderDaysSaga(),
        citySaga(),
        sendNewOrderCodeSaga(),
        getReview()
        // usersSaga(),
        // companyDataSaga(),
        // UserDataSaga()
    ]);
}