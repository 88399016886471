import { Posts } from "../constants";
const PopularMasters = (state = {
    loading: true,
    data: {}
}, action) => {
    switch (action.type) {
        case Posts.POPULAR_MASTER:
            return {
                ...state,
                results: action.payload?[...action.payload?.results]:[],
                loading: false
            };
        default:
            state;
    }
    return state;
};
export default PopularMasters;