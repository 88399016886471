import { Posts } from "../constants";
const PostsRedus = (state = { loading: true, results: [], count: 0 }, action) => {
    if (action.type === Posts.POSTS_LOADING) {
        return { ...state, more_loading: true };
    }
    if (action.type === Posts.ADD_POSTS_COMMENTS) {
        let data = { ...state };
        let array = data.results;
        for (let index = 0; index < array.length; index++) {
            const element = array[index];
            if (element.id === action.payload.post) {
                element.new_comment_value = action.payload.comment;
            }
        }
        return data;
    }
    if (action.type === Posts.ADD_COMMENT) {
        let data = { ...state };
        let array = data.results;
        for (let index = 0; index < array.length; index++) {
            const element = array[index];
            if (element.id === action.payload.post) {
                element.new_comment_value = "";
            }
        }
        return data;
    }
    if (action.type === Posts.POSTS_DELETE) {
        return { loading: true, results: [] };
    }
    if (action.type === Posts.POSTS_ARRAY_HASH_TAG) {
        let is_min = {};
        let is_min_index = "";
        let data = {
            count: action.payload.count,
            results: [...action.payload?.results],
            loading: false,
            more_loading: false
        };
        for (let index = 0; index < data.results.length; index++) {
            const element = data.results[index];
            let new_file_list = [];
            for (let ind = 0; ind < element.files.length; ind++) {
                const el = element.files[ind];
                if (el.is_mine) {
                    is_min = el;
                    is_min_index = index;
                }
                new_file_list.push(el.file_url);
            }
            if (is_min_index) {
                element.files.splice(is_min_index, 1);
                element.files.unshift(is_min);
                new_file_list.splice(is_min_index, 1);
                new_file_list.unshift(is_min);
            }
            element.file_url = new_file_list;

        }
        return data;
    }
    if (action.type === Posts.NEW_POSTS_ARRAY) {
        let data = { loading: true, results: state.results };
        if (action.payload.posts && action.payload.posts.results.length > 0) {
            let is_min = {};
            let is_min_index = "";
            data = {
                count: action.payload.posts.count,
                results: action.payload.query && action.payload.query.page ? [...state.results, ...action.payload.posts.results] : [...action.payload.posts.results],
                loading: false,
                more_loading: action.payload.posts.results.length !== action.payload.posts.count 
            };
            for (let index = 0; index < data.results.length; index++) {
                const element = data.results[index];
                let new_file_list = []; ``;
                for (let ind = 0; ind < element.files.length; ind++) {
                    const el = element.files[ind];
                    if (el.is_mine) {
                        is_min = el;
                        is_min_index = index;
                    }
                    new_file_list.push(el.file_url);
                }
                if (is_min_index) {
                    element.files.splice(is_min_index, 1);
                    element.files.unshift(is_min);
                    new_file_list.splice(is_min_index, 1);
                    new_file_list.unshift(is_min);
                }
                element.file_url = new_file_list;
            }
        }
        return { ...data, loading: false };
    }
    if (action.type === Posts.POSTS_ARRAY) {
        let data = { loading: true, results: [] };
        if (action.payload) {
            let is_min = {};
            let is_min_index = "";
            data = {
                count: action.payload.count,
                results: [...state.results, ...action.payload?.results],
                loading: false, more_loading: false
            };
            for (let index = 0; index < data.results.length; index++) {
                const element = data.results[index];
                let new_file_list = [];
                for (let ind = 0; ind < element.files.length; ind++) {
                    const el = element.files[ind];
                    if (el.is_mine) {
                        is_min = el;
                        is_min_index = index;
                    }
                    new_file_list.push(el.file_url);
                }
                if (is_min_index) {
                    element.files.splice(is_min_index, 1);
                    element.files.unshift(is_min);
                    new_file_list.splice(is_min_index, 1);
                    new_file_list.unshift(is_min);
                }
                element.file_url = new_file_list;
            }
        }
        return data;
    }
    return state;
};
export default PostsRedus;