import { Service } from "../../constants";
const SelectedMaster = (state = {
    loading: true,
    data: {}
}, action) => {
    switch (action.type) {
        case Service.SELECTED_MASTER_LOADING:
            return {
                ...state,
                loading: true
            }
        case Service.GET_SELECTED_MASTER:
            return {
                ...state,
                data: { ...action.payload },
                loading: false
            }
        default:
            state;
    }
    return state
}
export default SelectedMaster;