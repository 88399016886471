import { Master } from "../../constants";
const Review = (state = {
    loading: true,
    page: 1
}, action) => {
    switch (action.type) {
        case Master.REVIEW_LOADING:
            return {
                ...state,
                loading: true
            }
        case Master.REVIEW:
            return {
                ...state,
                ...action.payload,
                loading: false
            }
        default:
            state;
    }
    return state
}
export default Review;