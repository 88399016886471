import { Reviews } from "../constants";
export const get_without_review = (data) => {
    return ({
        type: Reviews.GET_WITHOUT_REVIEWS,
        payload: data
    })
}
export const set_without_review = (data) => {
    return ({
        type: Reviews.SET_WITHOUT_REVIEWS,
        payload: data
    })
}
export const get_with_review = (data) => {
    return ({
        type: Reviews.GET_WITH_REVIEWS,
        payload: data
    })
}
export const set_with_review = (data) => {
    return ({
        type: Reviews.SET_WITH_REVIEWS,
        payload: data
    })
}