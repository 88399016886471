import { Posts } from "../constants";
const PostsSlider = (state = {
    results: []
}, action) => {
    switch (action.type) {
        case Posts.SET_POSTS_SLIDER:
            return {
                ...action.payload,
                results: action?.payload?.results
            }
        default:
            state;
    }
    return state
}
export default PostsSlider;