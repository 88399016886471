import { Posts } from "../constants";
const helpInfo = (state = {
    loading: true,
}, action) => {
    switch (action.type) {
        case Posts.SET_HELP:
            return {
                ...state,
                ...action.payload,
                loading: false
            };
        
        default:
            state;
    }
    return state;
};
export default helpInfo;