import { call, put, takeEvery } from 'redux-saga/effects';
import { i18n } from 'next-i18next';
import { My_personal_details } from "../constants";
import {
    edit_my_data, get_me_act, submitted, get_inputs_values,
    email_submmited, edit_my_email, check_email_submmited, email_loading,
    phone_submmited, edit_my_phone, check_phone_submmited, phone_loading, set_footer
} from "../actions/my-info";
import Category from '../../services/api/my-info';
import General from '../../services/api/general';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { localeData } from 'moment';
const success_notify = (text) => toast.success(text ? text :"success", {
    position: toast.POSITION.TOP_CENTER
});
const error_notify = (text) => toast.error(text ? text : "process", {
    position: toast.POSITION.TOP_CENTER
});
function* edit(data) {
    try {
        const { token, ...rest } = data.payload;
        const post_data_list = Object.keys(rest);
        let city = rest.city_details.id ? rest.city_details.id : null;
        let trim_data = {};
        delete rest.city_details;
        yield put(submitted());
        for (let index = 0; index < post_data_list.length; index++) {
            const element = post_data_list[index];
            if (rest[element] === "" || rest[element] === null) {
                delete rest[element];
            } else if (typeof rest[element] === "string") {
                trim_data = { ...trim_data, [element]: rest[element].trim() };
            } else if (typeof rest[element] === "number") {
                trim_data = { ...trim_data, [element]: rest[element] };
            }
        }
        const edit_res = yield Category.edit_my_personal_details({ token, rest: { ...trim_data, city, send_email:rest?.send_email } });
        var my_data = {};
        if (edit_res.message === "OK") {
            my_data = yield Category.get_me_client(token);
            // document.cookie = `user_id=${JSON.stringify(my_data)}; path=/`;
            success_notify();
        } else {
            error_notify();
        }
        yield put(edit_my_data({ ...edit_res, ...my_data, open_alert: true }));

    } catch (e) {
        console.log(e);
    }
}
function* get_me(data) {
    try {
        const my_info = yield Category.get_me_client(data.payload.token);
        // if (my_info.status === 200 || my_info.status === 201) {
        if (my_info && my_info.id) {
            // document.cookie = `user=${JSON.stringify(my_info)}; path=/`;
            yield put(get_me_act({ ...my_info }));
        }
        // }
        // cb(my_info.message === "OK")

    } catch (e) {
        console.log(e);
    }
}
function* get_files_saga(data) {
    const { ...rest } = data.payload;
    let formData = new FormData();
    formData.append("file_url", rest.image);
    try {
        const file = yield General.set_files(formData);
        yield put(get_inputs_values({ label: "image", data: { value: file.url } }));
    } catch (e) {
        console.log(e);
    }
}
function* send_change_email_code(data) {
    const { cb, token, ...rest } = data.payload;
    try {
        yield put(email_submmited());
        if (rest.data.email.valid) {
            yield put(email_loading());
            const res = yield General.send_change_email_code({ email: rest.data.email.value, token });
            yield put(edit_my_email({ ...res }));
            res.message === "OK" ? cb() : null;
        }
    } catch (e) {
        console.log(e);
    }
}
function* check_change_email_code(data) {
    const { cb, token, ...rest } = data.payload;
    try {
        yield put(check_email_submmited());
        if (rest.data.confirm_code.valid) {
            yield put(email_loading());
            const res = yield General.check_change_email_code({ confirm_code: rest.data.confirm_code.value.replace(/\s/g, ''), email: rest.data.email.value, token });
            yield put(edit_my_email({ ...res }));
            if (res.message === "OK") {
                const my_data = yield Category.get_me_client(token);
                document.cookie = `user_id=${JSON.stringify(my_data.id)}; path=/`;
                yield put(edit_my_data({ ...my_data, open_alert: false }));
                cb();
                success_notify();
            } else {
                error_notify();
            }
        }
    } catch (e) {
        console.log(e);
    }
}
//
function* send_change_phone_code(data) {
    const { cb, token, ...rest } = data.payload;
    try {
        yield put(phone_submmited());
        if (rest.data.phone_number.valid) {
            yield put(phone_loading());
            const res = yield General.send_change_phone_code({ phone_number: rest.data.phone_number.value, token });
            yield put(edit_my_phone({ ...res }));
            res.message === "OK" ? cb() : error_notify(res.message);
        }
    } catch (e) {
        console.log(e);
    }
}
function* change_password(data) {
    const { cb,cbChanged, token, ...rest } = data.payload;
    try {
        const res = yield General.change_password({ ...rest, token });
        if (res?.message === "Password is changed.") {
            cbChanged();
            success_notify(i18n.t(res?.message));
        } else {
            cb();
            error_notify(i18n.t(res?.data?.message));
        }
    } catch (e) {
        console.log(e);
    }
}
function* set_password(data) {
    const { cb,cbChanged, token, ...rest } = data.payload;
    try {
        const res = yield General.set_password({ ...rest, token });
        if (res?.id) {
            cbChanged();
            success_notify(i18n.t(res?.message));
        } else {
            cb();
            error_notify(i18n.t(res?.data?.message));
        }
    } catch (e) {
        console.log(e);
    }
}
function* check_change_phone_code(data) {
    const { token, cb, ...rest } = data.payload;
    try {
        yield put(check_phone_submmited());
        if (rest.data.confirm_code.valid) {
            yield put(phone_loading());
            const res = yield General.check_change_phone_code({ confirm_code: rest.data.confirm_code.value.replace(/\s/g, ''), phone_number: rest.data.phone_number.value, token });
            yield put(edit_my_phone({ ...res }));
            if (res.message === "OK") {
                const my_data = yield Category.get_me_client(token);
                document.cookie = `user_id=${JSON.stringify(my_data.id)}; path=/`;
                yield put(edit_my_data({ ...my_data, open_alert: false }));
                cb();
                success_notify();
            } else {
                error_notify();
            }
        }
    } catch (e) {
        console.log(e);
    }
}
function* get_footer(data) {
    try {
        const footer = yield General.get_footer(data);
        yield put(set_footer(footer));
    } catch (e) {
        console.log(e);
    }
}
export function* footerSaga() {
    yield takeEvery(My_personal_details.GET_FOOTER, get_footer);
}
export function* editSaga() {
    yield takeEvery(My_personal_details.EDIT_MY_PERSONAL_DETAILS_SAGA, edit);
}
export function* getMeSaga() {
    yield takeEvery(My_personal_details.GET_MY_PERSONAL_DETAILS_SAGA, get_me);
}
export function* getFilesSaga() {
    yield takeEvery(My_personal_details.SET_FILES_SAGA, get_files_saga);
}
export function* editEmailSaga() {
    yield takeEvery(My_personal_details.EDIT_MY_EMAIL_SAGA, send_change_email_code);
}
export function* chackEmailSaga() {
    yield takeEvery(My_personal_details.CHACK_MY_EMAIL_SAGA, check_change_email_code);
}

export function* editPhoneSaga() {
    yield takeEvery(My_personal_details.EDIT_MY_PHONE_SAGA, send_change_phone_code);
}
export function* editPasswordSaga() {
    yield takeEvery(My_personal_details.CHANGE_PASSWORD, change_password);
}
export function* setPasswordSaga() {
    yield takeEvery(My_personal_details.SET_PASSWORD, set_password);
}
export function* chackPhoneSaga() {
    yield takeEvery(My_personal_details.CHACK_MY_PHONE_SAGA, check_change_phone_code);
}
