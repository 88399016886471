import { Master } from "../../constants";
const date_new = new Date();
// let date = data ? `${data.year}-${data.month}-${data.day}`
const MasterSchedule = (state = {
    loading: true,
    // calendar_value: {
    //     year: +date_new.getUTCFullYear(),
    //     month: +date_new.getUTCMonth() + 1,
    //     day: +date_new.getUTCDate()
    // }
}, action) => {
    switch (action.type) {
        case Master.GET_MASTER_SCHEDULE_LOADER:
            return {
                ...state,
                loading: true
            };
        case Master.SELECT_SCHEDULE:
            if (!state.selected_schedule || (action.payload && action.payload.start_time !== state.selected_schedule.start_time)) {
                return {
                    ...state,
                    selected_schedule: { ...action.payload }
                };
            } else {
                return {
                    ...state,
                    selected_schedule: {}
                };
            }

        case Master.SELECT_CARD:
            return {
                ...state,
                selected_card: action.payload
            };
        case Master.SELECT_ADDRESS:
            return action.payload
                ? {
                    ...state,
                    ...action.payload
                }
                : { ...state };
        case Master.DELETE_MASTER_SCHEDULE:
            return {
                ...state,
                selected_schedule: {}
            };
        case Master.GET_MASTER_SCHEDULE:
            const new_schedule = action.payload.schedule;
            return {
                ...state,
                loading: false,
                results: !new_schedule?.message ? new_schedule : [],
                date: action.payload.date,
                service: action.payload.service,
                calendar_value: action.payload.calendar_value,
            };
        case Master.AVAILABLE_DAYS:
            return {
                ...state,
                ...action.payload
            };
        default:
            state;
    }
    return state;
};
export default MasterSchedule;