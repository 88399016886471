import { Posts } from "../constants";
const PostData = (state = { loading: true, comments_loading: false, new_comment_value: "", new_child_comment_value: "" }, action) => {
    switch (action.type) {
        case Posts.DELETE_POST_DATA:
            return {
                loading: true, comments_loading: false, new_comment_value: "", new_child_comment_value: ""
            };
        case Posts.POST_DATA_LOADING:
            return {
                ...state,
                loading: true
            };
        case Posts.POST_DATA:
            return {
                loading: false,
                comments_loading: false,
                new_comment_value: "",
                new_child_comment_value: "",
                ...action.payload,
            };
        case Posts.POST_PAGE_COMMENTS_LOADING:
            return {
                ...state,
                comments_loading: true,
                see_comments: true,
            };
        case Posts.POST_PAGE_COMMENTS:
            return {
                ...state,
                comments_loading: false,
                comments: action.payload
            };
        case Posts.POST_PAGE_CHILD_COMMENT_INPUT_VALUE:
            return {
                ...state,
                new_child_comment_value: action.payload
            };
        case Posts.POST_PAGE_COMMENT_INPUT_VALUE:
            return {
                ...state,
                new_comment_value: action.payload
            };
        case Posts.POSTS_COMMENTS:
            let new_comment = { ...state };
            if (action.payload) {
                let list = new_comment?.comments?.results
                    ? [...new_comment.comments.results, ...action.payload?.results]
                    : action.payload?.results;
                if (list[list.length - 2] && list[list.length - 1].id === list[list.length - 2].id) {
                    list.pop();
                }
                new_comment = {
                    ...state,
                    comments: { count: action.payload.count, results: list },
                    more_loading: false, see_more: false,
                    comments_loading: false
                };
            }
            return new_comment;
        case Posts.ADD_POST_PAGE_COMMENT:
            let data = { ...state };
            if (action.payload) {
                if (action.payload.parent) {
                    for (let index = 0; index < data.comments.results.length; index++) {
                        const element = data.comments.results[index];
                        if (element.id === action.payload.parent) {
                            element.child_comments.push(action.payload);
                        }
                    }
                } else {
                    let list = data.comments ? data.comments.results : [];
                    list.unshift(action.payload);
                    data = { ...data, comments: { ...data.comments, results: list }, disabled: false };
                }
                data.comment_count += 1;
                data.new_comment_value = "";
                data.new_child_comment_value = "";
                data.disabled = false;
            }
            return data;
        default:
            state;
    }
    return state;
};
export default PostData;