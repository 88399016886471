import { General } from "../constants";
const menu = (state = {  }, action) => {
    switch (action.type) {
        case General.SET_MENU:
            return {
                ...action.payload
            };
        default:
            state;
    }
    return state;
};
export default menu;