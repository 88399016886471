import { Service } from "../constants";
const ServiceRedus = (state = {
    loading: true,
    data: { results: [] }
}, action) => {
    switch (action.type) {
        case Service.GET_SERVICE:
            return {
                ...state,
                data: { ...action.payload, results: !action.payload.more ? [...action.payload?.results] : [...state.data.results, ...action.payload?.results] },
                loading: false
            };
        case Service.GET_CITY:
            return {
                ...state,
                city: { ...action.payload },
            };
        case Service.DELETE_SERVER_ARRAY:
            let data = {
                ...state,
                loading: false,
                data: { results: [] }
            };
            return data;
        default:
            state;
    }
    return state;
};
export default ServiceRedus;