import { LoginRegis } from "../../constants";
const ForgetPassword = (state = {
    loading: false,
    showCode: false,
    data: {
        "role_code": "",
        "phone_number": { value: "", valid: false },
        "email": { value: "", valid: false },
        "confirm_code": { value: "", valid: false }
    }
}, action) => {
    switch (action.type) {
        case LoginRegis.GET_FORGET_INPUT_VALUES:
            let new_data = {
                ...state,
                data: {
                    ...state.data,
                    [action.payload.key]: action.payload.value,
                }
            }
            if (action.payload.key === "email") {
                new_data.phone_number = { value: "", valid: false }
            } else if (action.payload.key === "phone_number") {
                new_data.email = { value: "", valid: false }
            }
            return new_data
        case LoginRegis.DELETE_CODE:
            let data = {
                ...state,
                data: {
                    ...state.data,
                    code_message: "",
                    confirm_code: {
                        valid: false,
                        value: ""
                    }
                }
            }
            return data
        case LoginRegis.FORGET_PASSWORD_LOADING:
            return {
                ...state,
                loading: true,
            }
        case LoginRegis.PHONE_OR_EMAIL_RES:
            return {
                ...state,
                data: {
                    ...state.data,
                    ...action.payload,
                },
                loading: false,
                submitted: false
                // showCode: action.payload.message === "OK" ? true : false
            }
        case LoginRegis.CODE_RES:
            let { message, ...rest } = action.payload;
            if (action.payload.message === "OK") {
                return {
                    ...state,
                    submitted_code: false,
                    data: {
                        ...state.data,
                        code_message: message,
                        ...rest,
                        role_code: "",
                        phone_number: { value: "", valid: false },
                        email: { value: "", valid: false },
                        confirm_code: { value: "", valid: false },
                    },
                    loading: false
                }
            } else {
                return {
                    ...state,
                    data: {
                        ...state.data,
                        code_message: message,
                        ...rest,
                    },
                    loading: false
                }
            }

        case LoginRegis.CLOSE_FORGET_ALERT:
            return {
                ...state,
                data: {
                    ...state.data,
                    open_alert: false
                }
            }
        case LoginRegis.SUBMITTED_FORGET:
            return {
                ...state,
                submitted: true
            }
        case LoginRegis.SUBMITTED_CODE_FORGET:
            return {
                ...state,
                submitted_code: true
            }
        default:
            state;
    }
    return state
}
export default ForgetPassword;