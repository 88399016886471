import { call, debounce, put, takeEvery } from 'redux-saga/effects';
import { Note } from "../constants";
import {
    my_note,
    cancel_note,
    cancel_note_loading,
    add_review,
    add_review_loading,
    my_note_loading,
    pay_for_order,
    pay_for_order_loading,
    set_client_order_days
} from "../actions/note";
import Category from '../../services/api/note';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const success_notify = (text) => toast.success(text ? text : "success", {
    position: toast.POSITION.TOP_CENTER
});
const error_notify = () => toast.error("error", {
    position: toast.POSITION.TOP_CENTER
});
function* my_notes(data) {
    const { from_date, to_date, ...rest } = data.payload;
    try {
        yield put(my_note_loading());
        const notes = yield Category.get_note(data.payload);
        // if (notes.data && notes.data.status !== 500) {
        yield put(my_note({ notes, type: data.payload.type, ...rest, date: to_date }));
        // }
    } catch (e) {
        console.log(e);
    }
}
function* get_client_order_days(data) {
    try {
        const days = yield Category.get_client_order_days(data.payload);
        // if (notes.data && notes.data.status !== 500) {
        yield put(set_client_order_days(days));
        // }
    } catch (e) {
        console.log(e);
    }
}
function* cancel_note_saga(data) {
    const { cb, params, user_id, translate, ...rest } = data.payload;
    try {
        yield put(cancel_note_loading());
        const notes = yield Category.cancel_note(rest);
        const notes_list = yield Category.get_note({ ...params, user_id, token: rest.token });
        yield put(my_note({ notes: notes_list, type: data.payload.type }));
        // const open_alert = notes.status > 400 ? "error" : "success";
        yield put(cancel_note({ ...notes.data }));
        // notes.status < 400 ? cb() : null;
        if (notes.status < 400) {
            cb();
            success_notify(translate.process_successful);
        } else {
            error_notify();
        }
    } catch (e) {
        console.log(e);
    }
}
function* add_review_saga(data) {
    const { cb, ...rest } = data.payload;
    try {
        yield put(add_review_loading());
        const notes = yield Category.add_review(rest);
        // yield put(add_review({ ...notes.data }));
        notes.status < 400 ? cb() : error_notify();
    } catch (e) {
        console.log(e);
    }
}
function* pay_for_oder(data) {
    const { cb, loading_true, loading_false, ...rest } = data.payload;
    try {
        loading_true();
        const notes = yield Category.pay_for_order(rest);
        yield notes.status > 400 ? error_notify() : success_notify();
        // loading_false();
        // yield put(pay_for_order({ ...notes.data, open_alert }));
        yield notes.status < 400 ? cb() : loading_false();
        // if (notes.iframe_url) {
        //     openTab(notes.iframe_url)
        // }
    } catch (e) {
        console.log(e);
    }
}
function* pay_for_order_without_add_card(data) {
    const { cb, loading_true, loading_false, ...rest } = data.payload;
    try {
        loading_true();
        const notes = yield Category.pay_for_order_without_add_card(rest);
        yield notes.status > 400 ? error_notify() : success_notify();
        // loading_false();
        // yield put(pay_for_order({ ...notes.data, open_alert }));
        yield notes.status < 400 ? cb() : loading_false();
        // if (notes.iframe_url) {
        //     openTab(notes.iframe_url)
        // }
    } catch (e) {
        console.log(e);
    }
}
export function* noteSaga() {
    yield debounce(1000, Note.MY_NOTE_SAGA, my_notes);
}
export function* cancelNoteSaga() {
    yield takeEvery(Note.CANCEL_COMMENT_SAGA, cancel_note_saga);
}
export function* addReviewSaga() {
    yield takeEvery(Note.ADD_REVIEW_SAGA, add_review_saga);
}
export function* payForOrderSaga() {
    yield takeEvery(Note.PAY_FOR_ORDER_SAGA, pay_for_oder);
}
export function* payForOrderWithoutAddCard() {
    yield takeEvery(Note.PAY_WITHOUT_ADD_CARD_SAGA, pay_for_order_without_add_card);
}
export function* getClientOrderDaysSaga() {
    yield takeEvery(Note.GET_CLIENT_ORDER_DAYS_SAGA, get_client_order_days);
}