import { General } from "../constants";
const City = (state = {
    loading: false,
    city: { results: [], search: "" }
}, action) => {
    switch (action.type) {
        case General.GET_CITY_LOADING:
            return {
                ...state,
                loading: true
            }
        case General.GET_CITY:
            return {
                ...state,
                city: { ...action.payload },
                loading: false
            }
        default:
            state;
    }
    return state
}
export default City;