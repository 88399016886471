import { put, takeEvery } from 'redux-saga/effects';
import { Reviews } from "../constants";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import category from '../../services/api/note';
import { set_with_review, set_without_review } from '../actions/reviews';
// const success_notify = () => toast.success("success", {
//     position: toast.POSITION.TOP_CENTER
// })
const error_notify = (text) => toast.error(text ? text : "Something went wrong", {
    position: toast.POSITION.TOP_CENTER
});
function* get_without_review(data) {
    const { cb, ...rest } = data.payload;
    
    try {
        const without_review = yield category.without_review(rest);
        yield put(set_without_review({...without_review,without_review_count:without_review.data.length }));
        // cb(file)
    } catch (e) {
        console.log(e);
    }
}
function* get_with_review(data) {
    const { cb, ...rest } = data.payload;
    
    try {
        const with_review = yield category.with_review(rest);
        yield put(set_with_review(with_review));
        // cb(file)
    } catch (e) {
        console.log(e);
    }
}
export function* getReview() {
    yield takeEvery(Reviews.GET_WITHOUT_REVIEWS, get_without_review);
    yield takeEvery(Reviews.GET_WITH_REVIEWS, get_with_review);
}



