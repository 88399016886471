import authApi, { setApiAuthorizationHeader } from "../authinstance";
import keys from "../keys";
const queryString = require('query-string');

class Auth {
    async get_room(inf) {
        try {
            setApiAuthorizationHeader(inf.token)
            const response = await authApi.post(`${keys.SERVER_URL}chat/get-room-for-client/ `, inf.data);
            return response.data
        } catch (error) {
            return error.response
        }
    }
}
const category = new Auth();
export default category;
