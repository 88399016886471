import { call, put, takeEvery } from 'redux-saga/effects';
import { Card } from "../constants";
import {
    master_card,
    create_account_act,
    add_card,
    submit,
    create_card_loading,
    get_history,
    card_loading,
    history_loading
} from "../actions/card";
import Category from '../../services/api/card';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const success_notify = (text) => toast.success(text, {
    position: toast.POSITION.TOP_CENTER
});
const error_notify = (text) => toast.error(text ? text : "error", {
    position: toast.POSITION.TOP_CENTER
});
function* get_card(data) {
    try {
        yield put(card_loading());
        const cards = yield Category.get_card(data.payload);
        yield put(master_card({ ...cards }));
    } catch (e) {
        console.log(e);
    }
}
function* create_account(data) {
    try {
        const account = yield Category.create_account(data.payload);
        yield put(create_account_act({ ...account }));
        window.open(account.redirect_url, '_blank');
    } catch (e) {
        console.log(e);
    }
}
function* delete_card_saga(data) {
    const { cb, ...payload } = data.payload;
    try {
        const account = yield Category.delete_card(payload);
        yield put(create_account_act({ ...account }));
        const cards = yield Category.get_card(data.payload);
        yield put(master_card({ ...cards }));
        yield cb();
    } catch (e) {
        console.log(e);
    }
}
function* add_card_saga(data) {
    const post_data = data.payload.data;
    const translate = data.payload.translate;
    const kays = Object.keys(post_data);
    let post = {};
    try {
        yield put(submit());

        for (let index = 0; index < kays.length; index++) {
            const element = kays[index];
            if (index < 4 && !post_data[element].valid) {
                return;
            }
            else if (element === "phone_number" && post_data[element].value.length > 4 && !post_data[element].valid) {
                return;
            }
            else if (element === "email" && post_data[element].value.length > 0 && !post_data[element].valid) {
                return;
            }
            else {
                post = {
                    ...post,
                    [element]: post_data[element].value
                };
            }
        }
        yield put(create_card_loading());
        const account = yield Category.create_card({ token: data.payload.token, data: post });
        yield put(add_card(account.status && account.status === 500 ? { status: account.status } : { ...account }));
        if (account.id) {
            success_notify(translate.process_successful);
            data.payload.cb();
            if (account.next_action.redirect_to_url) {
                window.open(account.next_action.redirect_to_url.url, '_blank');
            }
        }
    } catch (e) {
        console.log(e);
    }
}
function* get_history_list(data) {
    try {
        yield put(history_loading());
        const history_list = yield Category.get_history(data.payload);
        yield put(get_history({ ...history_list, page: data.payload.page + 1 }));
    } catch (e) {
        console.log(e);
    }
}
export function* getCard() {
    yield takeEvery(Card.GET_CARD_SAGA, get_card);
}
export function* createAccount() {
    yield takeEvery(Card.CREATE_ACCOUNT_SAGA, create_account);
}
export function* addCard() {
    yield takeEvery(Card.ADD_CARD_SAGA, add_card_saga);
}
export function* deleteCard() {
    yield takeEvery(Card.DELETE_CARD_SAGA, delete_card_saga);
}
export function* getHistory() {
    yield takeEvery(Card.GET_HISTORY_SAGA, get_history_list);
}

