import { call, put, takeEvery } from 'redux-saga/effects';
import { Chat } from "../constants";
import Category from '../../services/api/chat';
import General from "../../services/api/general";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// const success_notify = () => toast.success("success", {
//     position: toast.POSITION.TOP_CENTER
// })
const error_notify = (text) => toast.error(text ? text : "Something went wrong", {
    position: toast.POSITION.TOP_CENTER
});
function* get_files_saga(data) {
    const { cb, translate, ...rest } = data.payload;
    let formData = new FormData();
    formData.append("file_url", rest.image)
    try {
        const file = yield General.set_files(formData);
        if (!file) {
            error_notify()
        }
        // yield put(get_inputs_values({ label: "image", data: { value: file } }));
        cb(file)
    } catch (e) {
        console.log(e);
    }
}
function* get_room_for_client(data) {
    const { cb, ...rest } = data.payload;
    try {
        const res = yield Category.get_room(rest);
        document.cookie = `room=${res.id}; path=/`;
        document.cookie = `chat_master=${+rest.data.user_id}; path=/`;
        // yield put(get_inputs_values({ label: "image", data: { value: file } }));
        cb();
    } catch (e) {
        console.log(e);
    }
}
export function* getFile() {
    yield takeEvery(Chat.CHAT_FILE, get_files_saga);
}
export function* get_room() {
    yield takeEvery(Chat.GET_ROOM_FOR_CLIENT_SAGA, get_room_for_client);
}


