import { Reviews } from "../constants";
const ReviewRedus = (state = {
    loading: true,
    data: []
}, action) => {
    switch (action.type) {
        case Reviews.SET_WITHOUT_REVIEWS:
            return {
                ...state,
                ...action.payload,
                loading: false
            };
        case Reviews.SET_WITH_REVIEWS:
            return {
                ...state,
                ...action.payload,
                loading: false
            };
        default:
            state;
    }
    return state;
};
export default ReviewRedus;